<template>
  <div
    class="card"
    :style="{
      background: value.background_color
    }"
  >
    <div class="card__info">
      <h2>
        {{ $t('components.ui.pages.pop-up.container-info.container') }} {{ value.name }}
      </h2>

      <img
        v-if="$store.state.window.width <= 768"
        :src="value.image_url"
        class="card__info__image"
      >

      <div
        class="card__info__attributes"
        :class="{
          'mobile-hide': value.need_more_products_turnover && $store.state.window.width <= 768
        }"
      >
        <div class="card__info__attributes__item">
          <div class="card__info__attributes__item__name">
            <img src="@/static/svg/store/container/income.svg">

            {{ $t('components.ui.pages.store.container-card.income') }}
          </div>

          <div class="card__info__attributes__item__value">
            {{ value.percent }}% — {{ $t(`components.ui.pages.store.container-card.income-${value.time_unit}`, { n: value.payouts_period }) }}
          </div>
        </div>

        <div class="card__info__attributes__item">
          <div class="card__info__attributes__item__name">
            <img src="@/static/svg/store/container/payouts.svg">

            {{ $t('components.ui.pages.store.container-card.payouts') }}
          </div>

          <div class="card__info__attributes__item__value">
            {{ $t(`components.ui.pages.store.container-card.payouts-${value.time_unit}`, { n: value.payouts_period }) }}
          </div>
        </div>

        <div class="card__info__attributes__item">
          <div class="card__info__attributes__item__name">
            <img src="@/static/svg/store/container/calendar.svg">

            {{ $t('components.ui.pages.store.container-card.yield-period') }}
          </div>

          <div class="card__info__attributes__item__value">
            {{ _yieldPeriod(value.time_unit, value.duration) }}
          </div>
        </div>
      </div>

      <div
        class="card__info__min"
        :class="{
          'mobile-hide': value.need_more_products_turnover && $store.state.window.width <= 768
        }"
      >
        <div class="card__info__min__progress">
          <div class="card__info__min__progress__percent">
            {{ $t('components.ui.pages.store.container-card.filled') }} {{ value.filled }}%
          </div>

          <div class="card__info__min__progress__bar">
            <div
              class="card__info__min__progress__bar__line"
              :style="{
                width: `${value.filled}%`
              }"
            />
          </div>
        </div>

        <div class="card__info__min__item">
          {{ $t('components.ui.pages.store.container-card.min-invest') }}:

          <span>
            $ {{ $format.wallet(value.minimal_invest) }}
          </span>
        </div>

        <div class="card__info__min__item">
          {{ $t('components.ui.pages.store.container-card.min-turnover') }}:

          <span>
            $ {{ $format.wallet(value.minimal_turnover) }}
          </span>
        </div>
      </div>
    </div>

    <div
      class="card__allow"
      :class="{
        hidden: value.need_more_products_turnover
      }"
    >
      <img
        v-if="$store.state.window.width > 768"
        :src="value.image_url"
      >

      <div
        v-if="!value.need_more_products_turnover"
        class="card__allow__nav"
      >
        <BlueButton
          tag="RouterLink"
          :to="{ name: 'StoreContainerCheckout', params: { id: value.id } }"
          :text="$t('components.ui.pages.store.container-card.buy')"
        />

        <ArrowButton
          :text="$t('components.ui.pages.store.container-card.view-container')"
          class="fs14"
          @click="$pop.up({ name: 'containerInfo', id: value.id })"
        />
      </div>

      <div
        v-else-if="$store.state.window.width > 768"
        class="card__allow__hide"
      >
        <div class="card__allow__hide__title">
          {{ $t('components.ui.pages.store.container-card.hide.not-available') }}
        </div>

        <p>
          {{ $t('components.ui.pages.store.container-card.hide.text') }}
        </p>

        <div class="card__allow__hide__not-enough">
          <div class="card__allow__hide__not-enough__start">
            {{ $t('components.ui.pages.store.container-card.hide.not-enough') }}:

            <div class="card__allow__hide__not-enough__start__value">
              ${{ $format.wallet(value.need_products_turnover) }}
            </div>
          </div>

          <div class="card__allow__hide__not-enough__turnover">
            {{ $t('components.ui.pages.store.container-card.hide.the-turnover-your-network') }}: ${{ $store.state.user.data.turnover_network_products }}
          </div>
        </div>

        <div class="card__allow__hide__nav">
          <BlueButton
            tag="a"
            :href="`https://onlix.store/${$i18n.locale === 'en' ? '' : $i18n.locale}`"
            target="_blank"
            :text="$t('components.ui.pages.store.container-card.hide.buy-product')"
          />

          <ArrowButton
            :text="$t('components.ui.pages.store.container-card.hide.invite-partner')"
            class="white fs14"
            @click="$pop.up('invite')"
          />
        </div>
      </div>
    </div>

    <div
      v-if="value.need_more_products_turnover && $store.state.window.width <= 768"
      class="card__allow__hide"
    >
      <div class="card__allow__hide__title">
        {{ $t('components.ui.pages.store.container-card.hide.not-available') }}
      </div>

      <p>
        {{ $t('components.ui.pages.store.container-card.hide.text') }}
      </p>

      <div class="card__allow__hide__not-enough">
        <div class="card__allow__hide__not-enough__start">
          {{ $t('components.ui.pages.store.container-card.hide.not-enough') }}:

          <div class="card__allow__hide__not-enough__start__value">
            ${{ $format.wallet(value.need_products_turnover) }}
          </div>
        </div>

        <div class="card__allow__hide__not-enough__turnover">
          {{ $t('components.ui.pages.store.container-card.hide.the-turnover-your-network') }}: ${{ $store.state.user.data.turnover_network_products }}
        </div>
      </div>

      <div class="card__allow__hide__nav">
        <BlueButton
          tag="a"
          :href="`https://onlix.store/${$i18n.locale === 'en' ? '' : $i18n.locale}`"
          target="_blank"
          :text="$t('components.ui.pages.store.container-card.hide.buy-product')"
        />

        <ArrowButton
          :text="$t('components.ui.pages.store.container-card.hide.invite-partner')"
          class="white fs14"
          @click="$pop.up('invite')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlueButton from '@/ui/buttons/BlueButton.vue'
import ArrowButton from '@/ui/buttons/ArrowButton.vue'

export default {
  components: {
    BlueButton,
    ArrowButton
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    archive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _archiveStatusPercent () {
      if (this.archive) {
        const status = this.value.delivery_status

        return status === 'purchase' ? 0
          : status === 'delivery' ? 50
            : status === 'implementation' ? 100 : 0
      } else {
        return false
      }
    }
  },
  methods: {
    _yieldPeriod (timeUnit, duration) {
      if (timeUnit === 'day' && (duration === 365 || duration === 364)) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      } else if (timeUnit === 'week' && duration === 52) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      } else if (timeUnit === 'month' && duration === 12) {
        return this.$t('components.ui.pages.store.container-card.year', { n: 1 })
      }

      return this.$t(`components.ui.pages.store.container-card.${timeUnit}`, { n: duration })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-columns: 330px auto;
  border-radius: 15px;
  overflow: hidden;
  gap: 20px;

  @media screen and (max-width: 1700px) {
    grid-template-columns: 500px auto;
    justify-content: space-between;
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: 350px auto;
  }

  @media screen and (max-width: 1100px) {
    gap: 0;
    grid-template-columns: 330px auto;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  &__info {
    position: relative;
    padding: 30px 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;

    @media screen and (max-width: 768px) {
      padding: 30px 15px 25px;
    }

    h2 {
      font-size: 32px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;

      @media screen and (max-width: 768px) {
        max-width: 150px;
        font-size: 26px;
        line-height: 110%;
      }
    }

    &__image {
      position: absolute;
      top: 10px;
      right: 0;
      height: 160px;
    }

    &__attributes {
      margin: 50px 0 60px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media screen and (max-width: 768px) {
        margin: 55px 0 30px;
        gap: 20px;
      }

      &.mobile-hide {
        @media screen and (max-width: 768px) {
          display: none !important;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__name {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 14px;
          line-height: 100%;
          color: rgba(32, 37, 57, .6);
          font-family: Medium;
        }

        &__value {
          font-size: 18px;
          line-height: 100%;
          text-align: right;
          color: #202539;
          font-family: SemiBold;
        }
      }
    }

    &__min {
      &.mobile-hide {
        @media screen and (max-width: 768px) {
          display: none !important;
        }
      }

      &__progress {
        margin-bottom: 30px;

        &__percent {
          margin-bottom: 5px;
          font-size: 12px;
          line-height: 100%;
          color: #202539;
          font-family: Medium;
        }

        &__bar {
          height: 8px;
          width: 100%;
          background: rgba(47, 87, 233, .2);
          border-radius: 4px;
          overflow: hidden;

          &__line {
            height: 100%;
            max-width: 100%;
            background: #2F57E9;
            border-radius: 10px;
          }
        }
      }

      &__item {
        margin-top: 15px;
        font-size: 12px;
        line-height: 100%;
        color: #787C8D;
        font-family: Medium;

        span {
          font-size: inherit;
          font-family: inherit;
          line-height: inherit;
          color: rgba(32, 37, 57, 1);
        }
      }
    }
  }

  &__allow {
    position: relative;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 1700px) {
      width: 340px;
    }

    @media screen and (max-width: 1100px) {
      width: 300px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &.hidden {
      overflow: hidden;
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      height: calc(100% - 25px);
      object-fit: contain;
      max-width: none;
    }

    &__nav {
      position: relative;
      width: 100%;
      padding: 0 30px 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      z-index: 2;

      @media screen and (max-width: 768px) {
        padding: 0 15px 25px;
      }
    }
  }

  &__allow__hide {
    position: static;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(32, 37, 57, .4);
    backdrop-filter: blur(20px);

    @media screen and (max-width: 768px) {
      margin-top: 25px;
      padding: 30px 15px;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 100%;
      color: #FFFFFF;
      font-family: SemiBold;
    }

    p {
      font-size: 14px;
      line-height: 140%;
      color: #FFFFFF;
    }

    &__not-enough {
      margin: 30px 0 25px;

      &__start {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 100%;
        color: #FFFFFF;
        font-family: Medium;

        &__value {
          font-size: 20px;
          line-height: 100%;
          text-align: right;
          color: #FFFFFF;
          font-family: SemiBold;
        }
      }

      &__turnover {
        margin-top: 5px;
        font-size: 12px;
        line-height: 100%;
        color: rgba(255, 255, 255, .6);
        font-family: Medium;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }
}
</style>
